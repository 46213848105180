import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/Layout'
import ProjectCategories from '../components/ProjectCategories'

export class BackToPhotoPageTemplate extends React.Component {
  render() {
    const { title, subtitle, photography = [] } = this.props

    return (
      <div>
        <div className="section backToPhoto" dataanchor="specialities">
          <div className="wide">
            <div className="title">
              <h5>{subtitle && subtitle}</h5>
              <h1>{title && title}</h1>
              <Link className="back" to="/back-to-work/">
                Back to all
              </Link>
            </div>
            <ProjectCategories categories={photography} from={`landing`}/>
          </div>
        </div>
      </div>
    )
  }
}

const BackToPhotoPage = ({ data: { page, photography } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <BackToPhotoPageTemplate
      {...page}
      {...page.fields}
      {...page.frontmatter}
      photography={photography.edges.map(post => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
    />
  </Layout>
)

export default BackToPhotoPage

export const pageQuery = graphql`
  query BackToPhotoPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      frontmatter {
        title
        subtitle
      }
    }

    photography: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "photography" } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            preview
          }
        }
      }
    }
  }
`
